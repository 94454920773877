<template>
  <v-form class="card" ref="initialReport" @submit.prevent="handleSubmit">
    <div class="card-header py-3">
      <div class="d-flex justify-content-between align-items-center">
        <span class="font-weight-medium">Form User</span>
        <v-btn icon class="" @click="handleClose">
          <i class="fa-solid fa-xmark"></i>
        </v-btn>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <v-switch
            inset
            label="Status"
            color="success"
            v-model="is_active"
          ></v-switch>
        </div>
        <div class="col-12">
          <v-text-field
            outlined
            dense
            placeholder="Nama Perusahaan"
            v-model="name"
            :rules="[
              (value) => {
                return genericRequiredRule(value, 'Nama Perusahaan');
              },
            ]"
          />
        </div>
        <div class="col-12">
          <v-select
            outlined
            dense
            placeholder="Role"
            :items="['USER', 'BC', 'ADMIN']"
            v-model="role"
            :rules="[
              (value) => {
                return genericRequiredRule(value, 'Role');
              },
            ]"
          />
        </div>
        <div class="col-12">
          <v-text-field
            outlined
            dense
            placeholder="Email Address"
            v-model="email"
            :rules="[
              (value) => {
                return genericRequiredRule(value, 'Email Address');
              },
              (value) => {
                return genericEmailRule(value, 'Email Address');
              },
            ]"
          />
        </div>
        <div class="col-12">
          <v-text-field
            outlined
            dense
            placeholder="NPWP"
            type="number"
            v-model="npwp"
            :rules="[
              (value) => {
                return genericRequiredRule(value, 'NPWP');
              },
              (value) => {
                return genericNumber16Rule(value, 'NPWP');
              },
            ]"
          />
        </div>
        <div class="col-12" v-if="!isUpdate">
          <v-text-field
            outlined
            dense
            placeholder="Password"
            type="password"
            v-model="password"
            :rules="[
              (value) => {
                return genericRequiredRule(value, 'Password');
              },
            ]"
          />
        </div>
        <div class="col-12">
          <v-textarea
            outlined
            dense
            v-model="address"
            placeholder="Alamat"
            :rules="[
              (value) => {
                return genericRequiredRule(value, 'Alamat');
              },
            ]"
          />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-end">
        <v-btn class="btn btn-secondary mr-2" @click="handleClose">
          Batal
        </v-btn>
        <v-btn type="submit" class="btn btn-primary">
          <span v-if="!isUpdate">Simpan</span>
          <span v-if="isUpdate">Update</span>
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";

export default {
  name: "UserForm",
  mixins: [ValidationRules],
  data() {
    return {
      //
    };
  },
  computed: {
    isUpdate() {
      return this.$store.state.user.isUpdate;
    },
    name: {
      get() {
        return this.$store.state.user.form.name;
      },
      set(value) {
        this.$store.commit("SET_FORM_USER", {
          key: "name",
          value,
        });
      },
    },
    role: {
      get() {
        return this.$store.state.user.form.role;
      },
      set(value) {
        this.$store.commit("SET_FORM_USER", {
          key: "role",
          value,
        });
      },
    },
    address: {
      get() {
        return this.$store.state.user.form.address;
      },
      set(value) {
        this.$store.commit("SET_FORM_USER", {
          key: "address",
          value,
        });
      },
    },
    email: {
      get() {
        return this.$store.state.user.form.email;
      },
      set(value) {
        this.$store.commit("SET_FORM_USER", {
          key: "email",
          value,
        });
      },
    },
    npwp: {
      get() {
        return this.$store.state.user.form.npwp;
      },
      set(value) {
        this.$store.commit("SET_FORM_USER", {
          key: "npwp",
          value,
        });
      },
    },
    password: {
      get() {
        return this.$store.state.user.form.password;
      },
      set(value) {
        this.$store.commit("SET_FORM_USER", {
          key: "password",
          value,
        });
      },
    },
    is_active: {
      get() {
        return this.$store.state.user.form.is_active;
      },
      set(value) {
        this.$store.commit("SET_FORM_USER", {
          key: "is_active",
          value,
        });
      },
    },
  },
  methods: {
    handleClose() {
      this.$refs.initialReport.reset();
      this.$store.commit("SET_IS_UPDATE_USER", "");
      this.$emit("handleModalForm");
    },
    handleSubmit() {
      this.$refs.initialReport.validate();
      if (this.$refs.initialReport.validate()) {
        if (this.isUpdate) {
          this.$store.dispatch("UpdateUser", this.isUpdate).then((val) => {
            if (val) this.handleClose();
          });
        } else {
          this.$store.dispatch("CreateUser").then((val) => {
            if (val) this.handleClose();
          });
        }
      }
    },
  },
};
</script>
