var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"initialReport",staticClass:"card",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"card-header py-3"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Form User")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.handleClose}},[_c('i',{staticClass:"fa-solid fa-xmark"})])],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-switch',{attrs:{"inset":"","label":"Status","color":"success"},model:{value:(_vm.is_active),callback:function ($$v) {_vm.is_active=$$v},expression:"is_active"}})],1),_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Nama Perusahaan","rules":[
            (value) => {
              return _vm.genericRequiredRule(value, 'Nama Perusahaan');
            },
          ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"col-12"},[_c('v-select',{attrs:{"outlined":"","dense":"","placeholder":"Role","items":['USER', 'BC', 'ADMIN'],"rules":[
            (value) => {
              return _vm.genericRequiredRule(value, 'Role');
            },
          ]},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1),_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Email Address","rules":[
            (value) => {
              return _vm.genericRequiredRule(value, 'Email Address');
            },
            (value) => {
              return _vm.genericEmailRule(value, 'Email Address');
            },
          ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"NPWP","type":"number","rules":[
            (value) => {
              return _vm.genericRequiredRule(value, 'NPWP');
            },
            (value) => {
              return _vm.genericNumber16Rule(value, 'NPWP');
            },
          ]},model:{value:(_vm.npwp),callback:function ($$v) {_vm.npwp=$$v},expression:"npwp"}})],1),(!_vm.isUpdate)?_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Password","type":"password","rules":[
            (value) => {
              return _vm.genericRequiredRule(value, 'Password');
            },
          ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1):_vm._e(),_c('div',{staticClass:"col-12"},[_c('v-textarea',{attrs:{"outlined":"","dense":"","placeholder":"Alamat","rules":[
            (value) => {
              return _vm.genericRequiredRule(value, 'Alamat');
            },
          ]},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('v-btn',{staticClass:"btn btn-secondary mr-2",on:{"click":_vm.handleClose}},[_vm._v(" Batal ")]),_c('v-btn',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[(!_vm.isUpdate)?_c('span',[_vm._v("Simpan")]):_vm._e(),(_vm.isUpdate)?_c('span',[_vm._v("Update")]):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }